.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.space-between-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stretch-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.text-left {
  text-align: left;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-full-height {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.flex-row {
  flex-direction: row;
}

.box-shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
}

.flex-1 {
  flex: 1;
}
