$kendo-switch-off-track-text: $gray-500;
$kendo-switch-off-track-bg: $gray-500;
$kendo-switch-off-track-border: $gray-500;

$kendo-switch-on-track-text: $primary;
$kendo-switch-on-track-bg: $primary;
$kendo-switch-on-track-border: $primary;

$kendo-slider-draghandle-bg: $primary;
$kendo-slider-draghandle-border: $primary;
$kendo-slider-draghandle-hover-bg: $primary;
$kendo-slider-draghandle-hover-border: $primary;
$kendo-slider-draghandle-pressed-bg: $primary;
$kendo-slider-draghandle-pressed-border: $primary;
$kendo-slider-draghandle-size: 24px;
$kendo-slider-track-bg: $gray-300;
$kendo-slider-selection-bg: $primary;
$kendo-slider-draghandle-focus-shadow: none;

.k-loader-primary {
  color: $white !important;
  transform: scale(0.7);
}

.k-loader-primary.black {
  color: $black !important;
}

.k-loader-primary.primary {
  color: $primary !important;
}
