.nav {
  font-size: 16px;

  .nav-item {
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 12px;

    &.active {
      background-color: $white;
      border-radius: 16px;
    }

    i {
      font-size: 22px;
      color: $gray-400;
    }

    .nav-link {
      color: $gray-700;
    }
  }
}
