[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'dibo';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-alarm:before {
  content: '\e800';
} /* '' */
.icon-check:before {
  content: '\e801';
} /* '' */
.icon-close-1:before {
  content: '\e802';
} /* '' */
.icon-credits:before {
  content: '\e803';
} /* '' */
.icon-home:before {
  content: '\e804';
} /* '' */
.icon-info:before {
  content: '\e805';
} /* '' */
.icon-edit-1:before {
  content: '\e806';
} /* '' */
.icon-gear:before {
  content: '\e807';
} /* '' */
.icon-watch:before {
  content: '\e808';
} /* '' */
.icon-warning:before {
  content: '\e809';
} /* '' */
.icon-arrow-right:before {
  content: '\e80a';
} /* '' */
.icon-billing:before {
  content: '\e80b';
} /* '' */
.icon-calender:before {
  content: '\e80c';
} /* '' */
.icon-pause:before {
  content: '\e80d';
} /* '' */
.icon-chevron-left:before {
  content: '\e80e';
} /* '' */
.icon-chevron-right:before {
  content: '\e80f';
} /* '' */
.icon-play:before {
  content: '\e810';
} /* '' */
.icon-edit:before {
  content: '\e811';
} /* '' */
.icon-history:before {
  content: '\e812';
} /* '' */
.icon-bell:before {
  content: '\e813';
} /* '' */
.icon-menu:before {
  content: '\e814';
} /* '' */
.icon-bookmark:before {
  content: '\e815';
} /* '' */
.icon-eye-old:before {
  content: '\e816';
} /* '' */
.icon-my-leads-1:before {
  content: '\e817';
} /* '' */
.icon-map:before {
  content: '\e818';
} /* '' */
.icon-arrow-back:before {
  content: '\e819';
} /* '' */
.icon-profile:before {
  content: '\e81a';
} /* '' */
.icon-logout:before {
  content: '\e81b';
} /* '' */
.icon-trash:before {
  content: '\e81c';
} /* '' */
.icon-calender-1:before {
  content: '\e81d';
} /* '' */
.icon-chevron-down:before {
  content: '\e81e';
} /* '' */
.icon-delete:before {
  content: '\e820';
} /* '' */
.icon-download:before {
  content: '\e821';
} /* '' */
.icon-timer:before {
  content: '\e822';
} /* '' */
.icon-bubbles:before {
  content: '\e823';
} /* '' */
.icon-logout-1:before {
  content: '\e825';
} /* '' */
.icon-options:before {
  content: '\e826';
} /* '' */
.icon-plus:before {
  content: '\e827';
} /* '' */
.icon-eye-slash:before {
  content: '\e828';
} /* '' */
.icon-eye:before {
  content: '\e829';
} /* '' */
.icon-save:before {
  content: '\e82a';
} /* '' */
.icon-user:before {
  content: '\e82b';
} /* '' */
.icon-car-wash:before {
  content: '\e82c';
} /* '' */
.icon-more-credits:before {
  content: '\e82d';
} /* '' */
.icon-dashboard:before {
  content: '\e82e';
} /* '' */
.icon-dates:before {
  content: '\e82f';
} /* '' */
.icon-lock:before {
  content: '\e830';
} /* '' */
.icon-logout-2:before {
  content: '\e831';
} /* '' */
.icon-my-account:before {
  content: '\e832';
} /* '' */
.icon-my-leads:before {
  content: '\e833';
} /* '' */
.icon-payouts:before {
  content: '\e834';
} /* '' */
.icon-percentage:before {
  content: '\e835';
} /* '' */
.icon-pricing:before {
  content: '\e836';
} /* '' */
.icon-qr:before {
  content: '\e837';
} /* '' */
.icon-systems:before {
  content: '\e838';
} /* '' */
