@font-face {
  font-family: 'dibo';
  src: url('../../assets/fonts/dibo.eot?20767155');
  src: url('../../assets/fonts/dibo.eot?20767155#iefix') format('embedded-opentype'),
    url('../../assets/fonts/dibo.woff2?20767155') format('woff2'), url('../../assets/fonts/dibo.woff?20767155') format('woff'),
    url('../../assets/fonts/dibo.ttf?20767155') format('truetype'), url('../../assets/fonts/dibo.svg?20767155#dibo') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/Lato-Bold.ttf') format('truetype');
}
