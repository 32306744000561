/*
This .scss loop will create "margin helpers" and "padding helpers"
It will generate classes like:
.m-r-8 which gives margin-right 8 pixels.
.m-t-16 gives margin-top 16 pixels
.p-20 padding of 20 pixels and so on.
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
Third letter is the number of spacing in pixels.
*/

$spaceamounts: (12, 32, 40, 42, 64, 80);
$sides: (top, bottom, left, right, all);

@each $space in $spaceamounts {
  @each $side in $sides {
    @if $side == 'all' {
      .m-#{$space} {
        margin: #{$space}px;
      }

      .p-#{$space} {
        padding: #{$space}px;
      }
    } @else {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }

      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
}

/*
This loop will create "background-color helpers" for backgrounds.
It will generate classes like:
.bg-gray-100 to .bg-gray-900
*/
@each $scale, $color in $grays {
  .bg-gray-#{$scale} {
    background-color: $color !important;
  }
}

/*
This loop will create "color helpers" for fonts.
It will generate classes like:
.text-gray-100 to .text-gray-900
*/
@each $scale, $color in $grays {
  .text-gray-#{$scale} {
    color: $color !important;
  }
}
