.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  border-color: $white !important;
}

.btn {
  &:disabled,
  &.disabled {
    color: $white;
  }

  &.btn-primary,
  &.btn-primary:hover,
  &.btn-graded,
  &.btn-graded:hover,
  &.btn-outline-primary:hover {
    color: $white;
  }

  &.btn-graded {
    background: linear-gradient(90deg, rgba(135, 195, 67) 0%, rgba(135, 195, 67, 0.25) 100%);
    border: none;

    &:hover {
      background: $primary;
    }
  }

  &.btn-wash-program {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 7px !important;
    background-color: $white;
    border-color: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);

    .btn-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .number {
        width: 40px;
        // Used important to not get overwritten by the disabled styling of the button
        color: $gray-900 !important;
      }

      .title {
        font-size: 16px;
        font-weight: bold;
        // Used important to not get overwritten by the disabled styling of the button
        color: $gray-900 !important;
      }

      .description {
        font-size: 14px;
        // Used important to not get overwritten by the disabled styling of the button
        color: $gray-500 !important;
      }
    }

    .icon-play {
      color: $gray-200;
    }

    .icon-play,
    .icon-pause {
      font-size: 34px;
    }

    &.active {
      background-color: $primary;
      border-color: $primary !important;

      .number,
      .title,
      .description,
      .icon-pause {
        // Used important to not get overwritten by the disabled styling of the button
        color: $white !important;
      }
    }
  }

  &.select-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px 16px !important;
    background-color: $white;
    border-color: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    margin-bottom: 8px;

    &.active {
      border-color: $primary !important;
    }
  }
}

.button-group {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;

  button {
    margin-bottom: 1rem;
  }
}

.credits-button {
  color: $black;
  font-size: 18px;
  background-color: $gray-200;
  border-radius: 28px;
  padding: 8px;
  border: 1px solid $gray-200;
}
