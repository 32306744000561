.navbar {
  background-color: $white;
  height: $navbar-height;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);

  .navbar-toggler {
    border: none;
  }

  .navbar-brand {
    margin-left: auto;
    margin-right: auto;
  }
}
