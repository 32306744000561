/*******************************************************************
  Write your own mixins here
*******************************************************************/

@mixin center-content {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin space-between {
  display: flex;
  justify-content: space-between;
}

@mixin darkbox {
  display: flex;
  background-color: $gray-700;
  border-radius: $border-radius;
}
