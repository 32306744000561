/*******************************************************************
  Add import when you create a new utils style file
*******************************************************************/

// custom variables that aren't part of Bootstrap
@import 'variables';

// custom functions
@import 'functions';

// custom mixins
@import 'mixins';

// Bootstrap overrides
@import 'bootstrap-overrides';

// Kendu UI Variables
@import '@progress/kendo-theme-default/scss/_variables.scss';

// Kendu UI overrides
@import '_kendo-ui-overrides';
