.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  label {
    text-align: center;
  }

  .form-control {
    text-align: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.invalid-feedback {
  text-align: center;
}

.referal-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 32px;
  justify-content: space-between;
}
