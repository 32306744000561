body,
html {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  font-size: 16px;
  background: linear-gradient(190.42deg, #fff 0%, #ebf2ff 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

main {
  height: calc(100% - $navbar-height - $footer-height);
  min-height: calc(100vh - $navbar-height - $footer-height);
}

footer {
  height: $footer-height;
  padding: 0 24px;
}

hr {
  margin: 0.5rem 0 1rem 0 !important;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 24px 0;
  border-top: 1px solid $gray-300;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
}

.full-height {
  display: flex;
  flex-direction: column;
  height: calc(100% - $navbar-height - $footer-height);
  min-height: calc(100vh - $navbar-height - $footer-height);
}

.time-left {
  font-size: 48px;
  font-weight: bold;
}

.white-box {
  padding: 16px 12px;
  background-color: $white;
  border-radius: 18px;
  margin-bottom: 1rem;
}

.border-top {
  border-top: 1px solid $gray-300;
}
