.sidebar {
  display: flex;
  flex-direction: column;
  padding: 6px;
  background-color: $gray-100;
  width: 75%;
  box-shadow: 0 1px 6px 1px rgba(0, 0, 0, 0.2);
  z-index: 9999 !important;

  .sidebar-footer {
    margin-top: auto;
    padding: 0 1rem 1rem;

    .logout {
      display: flex;
      align-items: center;

      .icon-logout {
        font-size: 22px;
        color: $gray-400;
      }

      .logout-link {
        color: $gray-700;
        padding: $nav-link-padding-y $nav-link-padding-x;
      }
    }
    .homepage {
      font-size: 12px;
    }
  }
}

.ng-sidebar__backdrop {
  opacity: 0.5 !important;
  z-index: 9999 !important;
}
