.special-offer {
  color: $white;
  padding: 16px 12px;
  background-color: $primary;
  border-radius: 18px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);

  .icon-info {
    font-size: 32px;
  }
  h3 {
    font-weight: bold;
    margin-bottom: 0;
  }

  h4 {
    line-height: 24px;
    margin-bottom: 0;
  }
}
