header {
  &.car {
    background: left / cover url('/assets/images/car.webp');
    height: 225px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.green {
      background-color: rgba(135, 195, 67, 0.85);
    }

    &.orange {
      background-color: rgba(255, 110, 0, 0.85);
    }
  }

  .small-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 4px 10px;
    background-color: rgba(255, 110, 0, 0.85);
  }
}
